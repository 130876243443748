.toast-component-send-contact {
  display: flex;
  flex-direction: column;
  /* max-width: 620px; */
  /* width: 80%; */
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #d9fdf9;
  border: 2px solid #3ac1ae;
}

.toast-header-send-contact {
  font-family: 'Poppins bold', sans-serif;
  font-size: 14px;
  color: #202b53;
  margin-bottom: 8px;
}

.toast-body-send-contact {
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  color: #202b53;
}
