@import url('./css/fonts/fonts.css');

html,
body,
#root,
.background {
  width: 100%;
  max-width: 100vw;
  min-height: 100%;
  overflow-x: hidden;
  max-width: 1920px;
  margin: auto;
}

#root {
  overflow: hidden;
}

h1 {
  font-family: 'Poppins semibold', sans-serif;
  font-size: 32px;
  color: #202b53;
}

h2 {
  font-family: 'Poppins bold', sans-serif;
  font-size: 42px;
  color: #202b53;
}

h3 {
  font-family: 'Poppins bold', sans-serif;
  font-size: 25px;
  color: #202b53;
}

h4 {
  font-family: 'Poppins bold', sans-serif;
  font-size: 18px;
  color: #202b53;
}

p {
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  color: #202b53;
}

span {
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  color: #202b53;
}

.background {
  background-color: #10446a;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: -1710px;
  left: 0;
  z-index: -1;
  width: 3511px;
  height: 3534px;
}

@media (max-width: 1440px) {
  .rectangle,
  .wave,
  .purple-ring,
  .demande-green-wave,
  .purple-plus,
  .pale-green-ring,
  .yellow-plus,
  .yellow-rectangle-dotted,
  .green-ring,
  .contact-green-wave,
  .pale-pink-ring,
  .pale-pink-ring-devis,
  .green-wave-devis {
    display: none;
  }

  h1 {
    font-size: 29px !important;
  }

  h2 {
    font-size: 38px !important;
  }

  .title-bluesoft {
    font-size: 38px !important;
  }
}

@media (max-width: 1024px) {
  h1,
  h2 {
    font-size: 23px !important;
    line-height: 23px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
  }
  .title-bluesoft {
    font-size: 23px !important;
    line-height: 23px;
  }
}

@media (min-width: 768px) {
  .background {
    height: 3100px;
  }
}

@media (min-width: 1441px) {
  .background {
    height: 2415px;
  }
}
