/* Medium devices (desktops, 992px and up) */
.header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  max-width: 1920px;
  margin: auto;
}

nav {
  height: 100px;
}

.navbar:after {
  border-bottom: 2px solid #66a3ff;
}

nav.navbar.navbar-expand-xl.navbar-dark.navbar-padding {
  background: #181e30 0% 0% no-repeat padding-box;
  padding-bottom: 0;
}

div#navbar-mobile {
  padding-bottom: 10px;
  border-bottom: 2px solid #66a3ff;
  height: 100% !important;
}

img.logo {
  max-width: 134px;
}

.devis {
  background-color: #4188f2;
  padding-top: 0px;
}

.navbar-collapse-logo {
  display: none;
}

.navbar.navbar-padding {
  padding-right: 9%;
  padding-left: 9%;
}

.collapse.navbar-fullwidth {
  width: 100%;
}

.ul-full-width {
  width: 100%;
}

.poppins-r16 {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
}

.devis-btn {
  background-color: #0099df;
  color: white !important;
  padding: 4px;
  border-radius: 37px;
  border-style: solid;
  border-color: #202b53;
  border-width: 1px;
  font-size: 15px;
  font-family: 'Poppins regular', sans-serif;
  width: 164px;
  text-align: center !important;
  text-decoration: none !important;
}

.devis-btn:hover {
  background: white;
  color: #0099df !important;
}

.client-btn {
  background-color: transparent;
  color: white !important;
  border-color: white;
  padding: 4px;
  border-radius: 37px;
  border-style: solid;
  border-width: 1px;
  font-size: 15px;
  font-family: 'Poppins regular', sans-serif;
  width: 164px;
  text-align: center !important;
  text-decoration: none !important;
}

@media (min-width: 1024px) and (max-width: 1665px) {
  .devis-btn {
    font-size: 14px;
    padding: 2px 5px;
  }

  .client-btn {
    font-size: 14px;
    padding: 2px 5px;
    margin-left: 5px;
  }

  .logo {
    margin-right: 20px;
    height: 40px;
  }

  .poppins-r16 {
    font-size: 14px;
  }

  .navbar.navbar-padding {
    padding-right: 4%;
    padding-left: 9%;
  }
}

/* Small devices (tablets, 992px and up) */
@media (max-width: 1200px) {
  nav {
    height: auto;
  }

  .collapse:not(.show) {
    display: none !important;
  }

  div#navbar-mobile {
    padding-bottom: 10px;
    border-bottom: 0;
  }
  .navbar.navbar-padding {
    padding-right: 40px;
    padding-left: 40px;
  }

  nav.navbar.navbar-expand-xl.navbar-dark.navbar-padding {
    background: #181e30 0% 0% no-repeat padding-box;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .logo {
    display: none;
  }

  .navbar-collapse-logo {
    display: block;
    margin: auto;
    height: 23px;
  }

  .navbar-collapse {
    margin-top: 20px;
  }

  .devis-btn {
    font-size: 14px;
    padding: 2px 5px;
  }
  .client-btn {
    font-size: 14px;
    padding: 2px 5px;
    margin-top: 10px;
  }
}

/* Extra Small devices (phones, 768px and less) */
@media (max-width: 768px) {
  .collapse:not(.show) {
    display: none !important;
  }

  .logo {
    display: none;
  }

  .navbar-collapse-logo {
    display: block;
    margin: auto;
    transform: translateX(-25%);
    height: 23px;
  }

  .navbar-collapse {
    margin-top: 20px;
  }

  .logo {
    height: 20px;
    position: absolute;
    top: 20px;
  }

  .poppins-r16 {
    font-family: 'Poppins regular', sans-serif;
    font-size: 14px;
  }

  .devis-btn {
    background-color: #202b53;
    color: white;
    padding: 2px 5px;
    border-radius: 37px;
    border-style: solid;
    border-color: #202b53;
    border-width: 1px;
    font-size: 14px;
    font-family: 'Poppins regular', sans-serif;
    margin-bottom: 5px;
  }

  .client-btn {
    background-color: transparent;
    color: white;
    border-color: white;
    padding: 2px 5px;
    border-radius: 37px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-family: 'Poppins regular', sans-serif;
  }

  .collapse:not(.show) {
    display: none !important;
  }
}

@media (max-width: 425px) {
  .navbar-collapse-logo {
    display: block;
    margin: auto;
    transform: translateX(-24%);
    height: 23px;
  }
}
