.services {
  padding-right: 9%;
  padding-left: 9%;
  padding: 170px;
  position: relative;
  display: flex;
  flex-direction: row;
  background: linear-gradient(to right, #181e30, #114267);
  max-height: 640px;
}

.services > .row {
  margin-right: 0;
  margin-left: 0;
}

.services-b2b {
  font-size: 16px;
  color: #05339c;
  padding: 0;
  margin-bottom: 0;
  flex: 0 0 43%;
  max-width: 43%;
  display: none;
}

.mainTitle {
  line-height: 60px;
  color: white;
  padding: 0;
  margin-top: -15px;
  padding-top: 1em;
  font-size: 50px;
}

.services-subtext {
  font-size: 25px;
  color: white;
  line-height: 38px;
  margin-bottom: 55px;
  flex: 0 0 70%;
  padding: 0;
}

.services-row-btn {
  align-items: center;
}

a.m-1.services-btn-find {
  font-size: 22px;
  padding: 0.2em;
  background-color: #0099df;
  border: none;
  min-width: 300px;
}

a.m-1.services-btn-find:hover {
  background: white;
  color: #0099df !important;
}

.services-btn-find {
  padding: 1px 3px;
  background-color: #202b53;
  font-size: 16px;
  color: white !important;
  font-family: 'Poppins regular', sans-serif;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #202b53;
  text-align: center !important;
  text-decoration: none !important;
  max-width: 250px;
}

.services-btn-more {
  color: white !important;
  font-family: 'Poppins semibold', sans-serif;
  font-size: 16px;
  padding: 24px 32px;
  text-align: center !important;
  text-decoration: none !important;
  max-width: 250px;
}

.services-wp {
  position: absolute;
  top: 145px;
}

.services-subcontainer {
  width: 50%;
}

.services-wp img {
  width: 100%;
  height: 100%;
  max-height: 663px;
  max-width: 886px;
}

.rectangle {
  position: absolute;
  top: 161%;
  left: 112px;
}

/* @media (min-width: 1024px) and (max-width: 1440px) {
  .services {
    padding-right: 5%;
    padding-left: 9%;
    margin-top: 115px;
    position: relative;
  }

  .mainTitle {
    line-height: 60px;
    color: white;
    padding: 0;
    margin-top: -10px;
    flex: 0 0 55%;
    max-width: 55%;
    padding-top: 0.5em;
  }

  .services-subtext {
    font-size: 14px;
    color: white;
    line-height: 30px;
    margin-bottom: 40px;
    flex: 0 0 38%;
    max-width: 38%;
  }
} */

/* @media (min-width: 768px) and (max-width: 1024px) {
  .services {
    padding-right: 2%;
    padding-left: 4%;
    margin-top: 80px;
    position: relative;
  }

  .mainTitle {
    line-height: 40px;
    color: white;
    padding: 0;
    margin-top: -10px;
    flex: 0 0 55%;
    max-width: 55%;
    padding-top: 1em;
  }

  .services-subtext {
    font-size: 14px;
    color: white;
    line-height: 30px;
    margin-bottom: 40px;
    flex: 0 0 38%;
    max-width: 38%;
  }
} */

@media (max-width: 1440px) {
  .mainTitle {
    font-size: 29px;
    line-height: 38px;
  }
}
@media (min-width: 1024px) and (max-width: 1536px) {
  .services-wp {
    top: 150px;
  }

  .services {
    padding: 120px;
    max-height: 562px;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .services {
    position: relative;
    flex-direction: column-reverse;
    padding: 92px 0px 24px 0px;
    margin-top: 0px;
    max-height: inherit;
  }

  .services-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;
  }

  .services-row-btn {
    margin: auto;
  }

  .services > .row {
    margin-right: 0;
    margin-left: 0;
    justify-content: center !important;
  }

  .services-b2b {
    font-size: 16px;
    color: #05339c;
    padding: 0;
    margin-bottom: 0;
    flex: 0 0 100%;
    max-width: 100%;
    display: none;
  }

  .mainTitle {
    line-height: 23px;
    font-size: 23px !important;
    color: white;
    padding: 0;
    margin-top: -10px;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding-top: 1em;
    margin-bottom: 20px;
  }

  .services-subtext {
    font-size: 16px;
    color: white;
    line-height: 20px;
    margin-bottom: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding-left: 9%;
    padding-right: 9%;
  }

  .services-wp {
    display: flex;
    position: relative;
    top: 80px;
    right: auto;
    margin: auto;
    max-width: 100%;
    z-index: 0;
    transform: translate(0, -55px);
  }

  .services-wp img {
    max-width: 530px;
  }

  a.m-1.services-btn-find {
    font-size: 18px;
    padding: 0.2em;
    background: #0099df 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    min-width: 266px;
  }

  .img-men-p {
    width: 100%;
    height: auto;
  }
  .whycec-p {
    display: initial !important;
  }
}

.mainTitle span {
  color: #0099dd;
  font-family: 'Poppins semibold', sans-serif;
}

.mainTitle strong {
  color: #0099dd;
}

.services-subtext span {
  color: #0099dd;
}

.services-subtext strong {
  color: #0099dd;
}
