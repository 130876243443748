.footerext {
  display: flex;
  flex-direction: row;
  background-color: #191c2c;
  color: white;
  padding: 5% 9%;
  gap: 24px;
  max-height: 500px;
}

.btn-footer a {
  padding: 0.2em;
  background-color: #0099df;
  border: none;
  border-radius: 5px;
  text-align: center;
  color: white;
  text-decoration: none;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.text-blue {
  color: #4188f2;
}

.marge {
  margin-top: 1em;
}

.social {
  display: flex;
}

.icons-s {
  margin-left: 8px;
}

img.solution-logo-s {
  width: 166px;
}

.footer-1,
.footer-2,
.footer-3,
.footer-4 {
  width: 25%;
  flex-grow: 1;
  display: flex;
  align-items: normal;
  flex-direction: column;
}

.textlong {
  max-width: 250px;
}

.footer-link {
  color: white !important;
}

.footer-link :hover {
  color: #0099df !important;
}

.footer-link :visited {
  color: white;
}

.double-width {
  flex-grow: 2;
}

@media (max-width: 1024px) {
  .logo-s {
    margin: auto;
  }

  .textlong {
    max-width: none !important;
  }
}

@media (max-width: 768px) {
  .footerext {
    flex-direction: column;
  }

  .footer-1,
  .footer-2,
  .footer-3,
  .footer-4 {
    width: 100%;
  }
}
