.comment-title {
  text-align: center;
  margin-bottom: 60px;
  padding-left: 9%;
  padding-right: 9%;
}

.comment {
  padding-top: 70px;
  padding-bottom: 70px;
}

.back-grey {
  background-color: #f5f5f5;
}

@media (max-width: 1024px) {
  .comment-title {
    margin-bottom: 40px;
  }

  .back-grey {
    background-color: white;
  }
}
