.element-container {
  background-color: #ffffff;
  margin-left: 16px;
  padding: 16px 16px 16px 16px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.container-wrap .elements-data .element-demand {
  flex-direction: column;
  align-items: baseline;
  max-width: 17.5%;
}

.container-wrap .elements-data .element-amount {
  flex-direction: column;
  align-items: baseline;
  max-width: 17.5%;
}

.container-wrap .elements-data .element-demand:nth-last-child(-n + 2) {
  max-width: 20%;
}

.container-wrap .elements-data .element-demand .status-demand {
  margin-left: 0;
  width: fit-content;
}

.elements-data {
  display: flex;
}

.elements-data .element-demand {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.elements-data .element-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.element-demand {
  max-width: 214px;
  width: 100%;
}

.element-amount {
  max-width: 260px;
  width: 100%;
}

.element-notation {
  max-width: 190px;
  width: 100%;
}

.element-status {
  max-width: 310px;
  margin-left: auto;
}

.data-title,
.data {
  font-family: 'Poppins bold';
  font-size: 13px;
}

.data-title {
  color: #202b53;
  margin-right: 7px;
}

.data {
  color: #4188f2;
}

.detail-icon {
  min-width: 28px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.status-demand {
  font-family: 'Poppins regular';
  font-size: 12px;
  border-radius: 12px;
  opacity: 1;
  padding: 2px 12px;
  /*margin-left: 8px;*/
  margin-left: auto;
}

.yellow {
  color: #f2bd41;
  background-color: #fdf6d9;
  border: 1px solid #f2bd41;
}

.pink {
  color: #956ae1;
  background-color: #f4deff;
  border: 1px solid #956ae1;
}

.green,
.waiting-client {
  color: #3ac1ae;
  background-color: #d9fdf9;
  border: 1px solid #3ac1ae;
}

.red {
  color: #fc2525;
  background-color: #fc9494;
  border: 1px solid #fc2525;
}

.detail-icon {
  margin: auto 24px auto 24px;
  cursor: pointer;
}

.open {
  max-height: 250px;
}

.element-details-open {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.element-button {
  background: #4188f2 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  font-family: 'Poppins bold';
  color: #ffffff;
  font-size: 14px;
  padding: 18px 55px;
  cursor: pointer;
  margin-top: 40px;
}

.signing-statut {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.did-you-sign {
  font-family: 'Poppins bold';
  color: #202b53;
  font-size: 14px;
  margin-top: 44px;
  text-align: center;
}

.container-signing-button {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.container-signing-button span {
  font-family: 'Poppins semibold';
  font-size: 16px;
  color: #202b53;
  text-decoration: underline;
  margin-right: 50px;
  cursor: pointer;
}

.element-expert-biography {
  width: 100%;
  background-color: #d9e7fd;
  border: 1px solid #4188f2;
  border-radius: 7px;
  margin-top: 30px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.expert-biography-title {
  font-family: 'Poppins semibold';
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
  color: #202b53;
}

.expert-biography-content {
  font-family: 'Poppins regular';
  font-size: 14px;
  color: #202b53;
}

/* Style de la modal RDV */

.container-rdv-modal {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-rdv-modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #00000029;
  top: 0;
  left: 0;
}

.modal-rdv,
.modal-dropfile {
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 26px;
  max-width: 700px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 32px);
  margin: 16px 0 16px 0;
}

.modal-dropfile .cancel-file-modal {
  align-self: center;
}

.modal-rdv.accepted-rdv-modal {
  align-items: center;
  gap: 24px;
  min-height: auto;
  margin: auto;
}

.rdv-list {
  width: 100%;
}

.modal-rdv {
  min-height: 518px;
}

.modal-rdv-title,
.modal-dropfile-title {
  font-family: 'Poppins bold';
  font-size: 21px;
  color: #202b53;
}

.choose-rdv,
.choose-file {
  border-radius: 8px;
  opacity: 1;
  border: none;
  background: #4188f2 0% 0% no-repeat padding-box;
  margin: 0 auto;
  display: flex;
  color: #ffffff;
  font-family: 'Poppins bold';
  font-size: 16px;
  padding: 18px 40px;
  margin-bottom: 0;
  cursor: pointer;
}

.choose-rdv {
  margin-bottom: 24px;
}

.cancel-rdv-modal,
.cancel-file-modal {
  font-family: 'Poppins bold';
  font-size: 16px;
  color: #202b53;
  text-decoration: underline;
  cursor: pointer;
}

.rdv-input {
  margin-right: 10px;
}

.rdv-label {
  font-family: 'Poppins semibold';
  font-size: 16px;
  color: #202b53;
  margin-right: 10px;
}

.rdv-date,
.rdv-slot {
  font-family: 'Poppins regular';
  font-size: 16px;
  color: #202b53;
  margin-right: 10px;
}

.rdv-date-icon,
.rdv-slot-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.modal-files-details {
  display: flex;
  flex-direction: column;
}

.modal-type-files-title {
  font-family: 'Poppins bold';
  font-size: 16px;
  color: #202b53;
  margin-bottom: 5px;
}

.modal-input {
  border: unset;
  border: 1px solid #d5d2d2;
  border-radius: 8px;
  padding: 8px;
  min-width: 440px;
  appearance: none;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  font-family: 'Poppins regular';
  font-size: 14px;
}

.modal-input :focus-visible {
  outline: #4188f2 1px solid;
}

.modal-file-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-file-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-item-name {
  font-family: 'Poppins regular';
  font-size: 16px;
  line-height: 25px;
  max-width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.modal-item-icon {
  width: 10px;
  height: 10px;
  margin-left: 20px;
  cursor: pointer;
}

.container-img-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-img-label > img {
  width: 21px;
  height: 26px;
  margin-bottom: 15px;
}

/******************************
      USER RDV LIST STYLE
******************************/

.user-rdv-list {
  width: 100%;
  max-width: 420px;
}

.user-rdv-detail {
  display: grid;
  grid-template-columns: 180px 150px 90px;
  position: relative;
  margin-bottom: 10px;
}

.user-rdv-detail::after {
  content: '';
  width: calc(100% - 28px);
  height: 1px;
  background-color: #d9e7fd;
  opacity: 0.95;
  position: absolute;
  bottom: -5px;
  left: 28px;
}

.user-rdv-detail:last-child::after {
  display: none;
}

.rdv-no-good-date {
  grid-template-columns: 300px 60px 60px;
}

.user-rdv-input {
  display: flex;
}
