.demande-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 40px;
}

.demande-green-wave {
  position: absolute;
  right: 272px;
  top: -100px;
}

.purple-plus {
  position: absolute;
  right: 185px;
  top: 55px;
}

.demande-title {
  display: flex;
  flex-direction: row;
  padding-left: 2.5%;
  padding-right: 2.5%;
  align-items: center;
}

.demande-title h2 {
  min-width: 476px;
  max-width: 476px;
  width: 100%;
  line-height: 78px;
  font-size: 42px;
}

.demande-title p {
  width: 100%;
  min-width: 400px;
  max-width: 597px;
  font-size: 16px;
  color: #202b53;
  line-height: 38px;
  margin-bottom: 0;
  margin-left: 160px;
}

#demande-devisAn {
  position: absolute;
  top: -70px;
}

.demande-grid {
  display: flex;
  margin-top: 50px;
  padding-left: 9%;
  background: #191c2c;
  overflow: hidden;
}

.demande-grid > img {
  margin: 20px 0 20px 20px;
  width: 456px;
  height: 530px;
}

.demande-inner-grid {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-bottom: 80px;
  min-width: 52%;
}

.demande-icon-text {
  display: flex;
  flex-direction: row;
}

.demande-icon-text img {
  width: 57px;
  height: 57px;
  margin: 0 30px 0 0;
}

.demande-icon-text p {
  color: #202b53;
  font-size: 14px;
  line-height: 27px;
  max-width: 561px;
  /* min-width: 561px; */
  margin-bottom: 27px;
  font-size: 25px;
  color: white;
}

.demande-btn {
  padding: 24px 32px;
  background-color: #4188f2;
  font-size: 16px;
  color: white !important;
  font-family: 'Poppins regular', sans-serif;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #4188f2;
  width: 240px;
  margin-top: 20px;
  margin-left: 160px;
  font-size: 14px;
  font-family: 'Poppins semibold', sans-serif;
  padding: 25px 34px;
  text-align: center !important;
  text-decoration: none !important;
}

.demande-grid-picture {
  display: block;
  margin: auto;
}

.title-red {
  color: #f34a4a;
}
.title-green {
  color: #2ebca8;
}
.title-blue {
  color: #307df1;
}
.title-yellow {
  color: #f3bf48;
}

.title-bluesoft {
  color: #0099df;
}

.title-plateforme {
  font-size: 50px;
  color: white;
  margin-top: 50px;
  margin-bottom: 39px;
}

.demande-inner-grid {
  flex: 1;
}

.grey-bg {
  position: absolute;
  z-index: -10;
  overflow: hidden;
  top: -1050px;
  left: 84px;
  transform: rotate(4deg);
}

.pale-green-ring {
  position: absolute;
  right: 120px;
  bottom: -4px;
}

.yellow-plus {
  position: absolute;
  bottom: -140px;
  left: 104px;
}

.destiny p {
  margin-left: 25px;
}
.demande-picture {
  width: 100%;
}

.destiny-img {
  max-width: 933px;
  max-height: 1300px;
  display: flex;
}

@media (min-width: 1441px) {
  .demande-container {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .demande-picture {
    display: none;
  }

  .demande-title p {
    margin-left: 5%;
  }

  .demande-grid {
    padding-right: 9%;
  }

  .demande-grid > img {
    width: 300px;
    height: 348px;
  }

  .demande-icon-text {
    margin-left: 0;
  }

  .demande-icon-text p {
    min-width: 431px;
    max-width: 100%;
    color: white;
  }

  .demande-btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 300px) and (max-width: 1024px) {
  .demande-container {
    padding-right: 9%;
    padding-left: 9%;
    margin-top: 0;
  }

  .destiny h3 {
    font-size:20px ;
  }

  .title-plateforme {
    font-size: 23px;
    color: white;
    margin-top: 35px;
    margin-bottom: 22px;
    text-align: left;
  }

  .demande-grid-picture {
    min-width: 0;
  }

  .demande-picture {
    display: none;
  }

  .demande-title {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    align-items: baseline;
  }

  .demande-title h2 {
    min-width: auto;
  }

  .demande-title p {
    margin-left: 0;
    min-width: auto;
  }

  .destiny p {
    margin-left: 0;
  }

  .demande-icon-text p {
    font-size: 16px;
    line-height: 20px;
  }

  .title-red h3 {
    line-height: 20px;
    font-size: 16px;
  }

  .demande-grid {
    padding-left: 9%;
    margin: 0;
    padding-right: 9%;
    border-radius: 20px;
  }

  .demande-grid {
    /* max-width: 50%; */
    min-width: 250px;
    display: flex;
    align-items: center;
  }

  .demande-grid .demande-grid-picture * {
    max-width: 100%;
  }

  .demande-inner-grid {
    max-width: 100%;
    padding-left: 0;
    padding-bottom: 35px;
  }

  .demande-grid > img {
    display: none;
  }

  .demande-icon-text {
    display: flex;
    flex-direction: row;
    margin-left: 0;
  }

  .demande-icon-text p {
    min-width: auto;
    margin-bottom: 1rem;
  }

  .demande-btn {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .demande-grid {
    flex-direction: column;
  }

  .demande-grid .demande-grid-picture {
    max-width: 350px;
    width: 100%;
    min-width: auto;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 32px;
  }
}

@media (max-width: 425px) {
  .demande-icon-text {
    flex-direction: column;
    align-items: center;
  }

  .demande-icon-text > img {
    margin-bottom: 20px;
  }
}
