.who-case {
  width: 280px;
}
.who-tcase {
  margin: auto;
  background-color: #f5f5f5;
  padding: 1.5em;
  border-radius: 30px;
}

.who-title {
  text-align: center;
  margin-bottom: 30px;
}

.who-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 85px;
}

.subtile-who {
  font-size: 17px;
  color: #0099df;
  margin-top: 1em;
}

.who-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.background-img-4 {
  height: 550px;
  overflow: hidden;
}

.who-box {
  max-width: 1600px;
  margin: -12% 8% 0 8%;
  background: white;
  padding: 2% 4% 4% 4%;
  border-radius: 30px;
}

.who-box p {
  font-size: 16px;
  line-height: 20px;
}

.who-title h2 {
  margin-bottom: 30px;
}

@media (max-width: 1100px) {
  .who-box {
    margin-top: 0;
  }

  .background-img {
    height: 280px;
    overflow: hidden;
  }
}

@media (max-width: 890px) {
  .who-box {
    max-width: 1600px;
    margin: -20% 0 0 0;
    background: white;
    padding: 4%;
    border-radius: 30px;
  }
}

@media (max-width: 677px) {
  .who-box {
    margin: 0;
    margin-top: 80px;
  }

  .who-case {
    width: auto;
    padding-bottom: 3em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .whoimg {
    text-align: center;
  }
}
