.error-container {
  width: 100%;
  position: fixed;
  bottom: 40%;
  box-sizing: border-box;
  margin-top: 100px;
  font-family: 'Poppins bold', sans-serif;
  font-size: 18px;
  padding: 0 8px;
  text-align: center;
}

.error-text {
  margin-bottom: 50px;
}
