.article-template-related {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 36px;
}

.template-related-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 32px;
  color: #202b53;
  line-height: 48px;
  margin-bottom: 24px;
  text-align: center;
}

.template-related-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.template-related-card {
  width: 260px;
  height: 420px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  text-decoration: none;
}

.related-card-img {
  position: absolute;
  z-index: 1;
  height: 100%;
  transform: translateX(-25%);
}

.related-card-container {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  padding: 20px;
}

.related-card-date {
  font-family: 'Poppins regular', sans-serif;
  font-size: 12px;
  color: white;
  background-color: #191c2c96;
}

.related-card-title {
  font-family: 'Poppins medium', sans-serif;
  font-size: 14px;
  color: #ffffff;
  background-color: #191c2c96;
}
