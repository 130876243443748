.cookie-banner {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 20px #4188f221;
  border-radius: 10px;
  position: fixed;
  bottom: 30px;
  left: 10%;
  right: 10%;
  z-index: 1000;
  height: 155px;
}

.cookie-banner p {
  flex: 1;
  font-family: 'Poppins regular', sans-serif;
  margin: 0 20px;
  font-size: 14px;
}

.cookie-banner .cookie-icon {
  width: 46px;
  height: 46px;
}

.accept-button,
.decline-button {
  padding: 8px 15px;
  border: none;
  font-family: 'Poppins semibold', sans-serif;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 79px;
}

.accept-button {
  background-color: #4188f2;
  color: #ffffff;
}

.decline-button {
  background-color: transparent;
  color: #4188f2;
  margin-left: 10px;
}

.decline-button:hover {
  background-color: #f2f2f2;
}

.close-button {
  font-size: 20px;
  margin-left: 20px;
  color: #4188f2;
  cursor: pointer;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .cookie-banner {
    padding: 15px;
    bottom: 15px;
    left: 7%;
    right: 7%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cookie-banner p {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
  }

  .cookie-banner .cookie-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .accept-button,
  .decline-button {
    width: 48%;
    height: 60px;
    font-size: 15px;
    margin-top: 10px;
  }

  .decline-button {
    margin-left: 4%;
  }

  .close-button {
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .cookie-banner {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    bottom: 10px;
    left: 5%;
    right: 5%;
    height: auto;
  }

  .cookie-banner p {
    margin: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    width: 100%;
  }

  .cookie-banner .cookie-icon {
    width: 36px;
    height: 36px;
    margin-right: 15px;
  }

  .accept-button,
  .decline-button {
    width: 70%; /* Ajustement pour que les deux boutons tiennent côte à côte */
    height: 50px;
    font-size: 16px;
    margin-top: 10px;
  }

  .decline-button {
    margin-left: 4%; /* Ajustement pour un petit espace entre les boutons */
  }

  .close-button {
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 35px;
  }
}
