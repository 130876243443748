.cookie-icon-container {
  position: fixed;
  bottom: 30px;
  cursor: pointer;
  z-index: 1000;
  width: 144px;
  height: 144px;
  background-size: cover; /* Si vous utilisez une image de fond */
}

/* Pour les écrans de tablettes et mobiles */
@media (max-width: 768px) {
  .cookie-icon-container {
    width: 0px; /* Nouvelle taille réduite pour mobile */
    height: 0px; /* Nouvelle taille réduite pour mobile */
    bottom: 85px;
    left: -10px;
  }

  .cookie-icon {
    width: 100px;
  }
}
