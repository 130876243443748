@font-face {
  font-family: 'Poppins regular';
  src: url('./Poppins/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins italic';
  src: url('./Poppins/Poppins-Italic.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins medium';
  src: url('./Poppins/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins semibold';
  src: url('./Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins bold';
  src: url('./Poppins/Poppins-Bold.ttf');
  font-weight: 700;
  font-style: bold;
}
