.toast-component-error-occured {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #ffdfdf;
  border: 2px solid #ff3e3e;
  left: 30px;
  bottom: 30px;
}

.toast-header-error-occured {
  font-family: 'Poppins bold', sans-serif;
  font-size: 14px;
  color: #202b53;
  margin-bottom: 8px;
}

.toast-body-error-occured {
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  color: #202b53;
}
