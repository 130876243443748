#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.articles-page {
  display: flex;
  max-width: 1600px;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
  border-radius: 15px;
  margin: -12% 8% 0 8%;
  padding: 2% 4% 4% 4%;
}

.strong {
  font-weight: bold;
}

.articles-page-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 42px;
  color: #202b53;
  margin-bottom: 30px;
  text-align: center;
}

.articles-page-description {
  margin-bottom: 30px;
}

.articles-page-description p {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  color: #202b53;
  line-height: 20px;
  text-align: center;
}

.articles-section {
  display: flex;
  flex-direction: column;
}

.articles-page-all {
  margin-bottom: 48px;
}

.articles-section-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 24px;
  color: #202b53;
  line-height: 38px;
}

.articles-section-description {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  color: #202b53;
  line-height: 38px;
  max-width: 860px;
  margin-bottom: 32px;
}

.articles-search-form {
  display: flex;
  gap: 8px;
}

.articles-search-submit {
  max-width: fit-content;
  border-radius: 8px;
  background-color: #4188f2;
  border-color: #4188f2;
  padding: 18px 76px;
  border-style: none;
}

.articles-search-submit:disabled {
  background-color: #202b53;
}

.submit-search-text {
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  color: #ffffff;
}

.submit-search-icon {
  display: none;
  max-width: 24px;
}

.articles-search-name {
  box-sizing: border-box;
  color: #202b53;
  border-color: #202b53;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  padding: 18px 20px;
  width: 50%;
}

.articles-search-name::placeholder {
  color: #202b53;
}

/* Custom Select */

.articles-search-custom-select {
  position: relative;
  width: 50%;
}

.select-selected {
  background-color: #ffffff;
  height: 100%;
  border: 1px solid #202b53 !important;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  color: #202b53;
  cursor: pointer;
}

/* arrow style */
.select-selected:after {
  position: absolute;
  content: '';
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-color: #202b53 transparent transparent transparent;
  transform: translateY(-25%);
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #202b53 transparent;
}

.select-items div {
  color: #202b53;
  padding: 8px 16px;
  border: 1px solid #202b53;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

.select-items {
  position: absolute;
  background-color: #ffffff;
  top: 101%;
  left: 0;
  right: 0;
  z-index: 99;
  border: 1px solid #202b53;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 212px;
}

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Articles search results */

.articles-search-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 20px;
}

.article-preview {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  border-radius: 22px;
  box-sizing: border-box;
  padding: 21px;
}

.article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.article-date {
  font-family: 'Poppins regular', sans-serif;
  font-size: 10px;
  line-height: 16px;
  color: #202b53;
  margin-right: 10px;
}

.article-activity {
  width: fit-content;
  box-sizing: border-box;
  padding: 4px 22px;
  border: 1px solid #202b53;
  border-radius: 50px;
  text-align: center;
  color: #202b53;
  font-family: 'Poppins regular', sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.activity-Taxation {
  border-color: #956ae1;
  color: #956ae1;
}

.activity-National_law {
  border-color: #f2bd41;
  color: #f2bd41;
}

.activity-General_information {
  border-color: #4188f2;
  color: #4188f2;
}

.activity-Accounting {
  border-color: #3ac1ae;
  color: #3ac1ae;
}

.article-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 24px;
  color: #202b53;
  line-height: 35px;
  text-decoration: none;
}

.article-description {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  color: #202b53;
  line-height: 28px;
  max-height: 110px;
  overflow: hidden;
}

.article-link {
  font-family: 'Poppins bold', sans-serif;
  font-size: 14px;
  color: #4188f2 !important;
  margin: auto auto 0 auto;
}

.articles-page-footer {
  width: 100%;
  height: 125px;
  background-color: #4188f2;
  margin-top: auto;
}

@media (max-width: 1440px) {
  .articles-search-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  h1,
  h2 {
    font-size: 23px !important;
    line-height: 23px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .articles-page {
    margin-top: 0;
    margin-bottom: 110px;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .articles-search-submit {
    padding: 18px 18px;
  }

  .submit-search-text {
    display: none;
  }

  .submit-search-icon {
    display: block;
  }

  .articles-search-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .article-title {
    font-size: 20px;
  }
}

@media (max-width: 677px) {
  .articles-search-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .articles-page {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    margin-top: 80px;
    margin-bottom: 200px;
    background: white;
    border-radius: 15px;
  }

  .background-img {
    height: initial;
    overflow: hidden;
  }
}

@media (max-width: 425px) {
  .articles-search-form {
    flex-direction: column;
  }

  .articles-search-custom-select,
  .articles-search-name {
    width: 100%;
  }

  .articles-search-submit {
    margin: auto;
  }

  .submit-search-text {
    display: inline-block;
    margin-right: 16px;
  }

  .submit-search-icon {
    display: inline-block;
  }
}
