.article-template-img {
  display: flex;
  width: 100%;
  gap: 5%;
  margin-bottom: 12px;
}

.article-template-img-reverse {
  flex-direction: row-reverse;
}

.template-img-content-column {
  width: 50%;
}

.template-img-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 32px;
  color: #202b53;
  line-height: 48px;
  margin-bottom: 24px;
}

.template-img-content-paragraphs {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.template-img-content-paragraph {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  color: #202b53;
  line-height: 32px;
  min-height: 16px;
  margin-bottom: 0;
}

.paragraph-content-column {
  display: flex;
  flex-direction: column;
}

.paragraph-content-column span {
  display: block;
}

.template-img-content-paragraphs .template-img-content-paragraph:last-child {
  margin-bottom: 24px;
}

.template-img-button {
  width: fit-content;
  border-radius: 8px;
  border: none;
  background-color: #4188f2;
}

.template-img-button-link {
  text-decoration: none;
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  color: #ffffff;
  line-height: 32px;
  padding: 12px 76px;
  display: block;
}

/* Image */

.template-img-image-column {
  display: flex;
  width: 50%;
}

.article-template-img-reverse .template-img-picture {
  justify-content: flex-start;
}

.template-img-picture {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;
}

.template-img-image {
  border-radius: 8px;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@media (max-width: 768px) {
  .article-template-img {
    flex-direction: column;
  }

  .article-template-img-reverse {
    flex-direction: column-reverse;
  }

  .article-template-img > div:first-child,
  .article-template-img-reverse > div:last-child {
    margin-bottom: 36px;
  }

  .template-img-content-column,
  .template-img-image-column {
    width: 100%;
  }

  .template-img-button {
    margin: auto;
    display: flex;
  }

  .template-img-image-column {
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: auto;
  }

  .template-img-image {
    width: 100%;
    position: relative;
    max-height: none;
    transform: none;
    left: auto;
    top: auto;
  }
}
