@media (max-width: 700px) {
  .devis-subtext,
  .devis-btn-help,
  .devis-link {
    display: none;
  }

  .devis-right-column {
    padding: 0 !important;
    margin: 0 !important;
  }

  .devis-form-label-box {
    font-size: 14px !important;
  }

  .devis-form-label {
    font-size: 14px !important;
  }

  .devis-container-steps {
    margin: 12% 0 0 0 !important;
  }

  .devis-btn-steps.all {
    margin-top: 10% !important;
  }
  .form-step {
    overflow: inherit !important;
  }

  .devis-container {
    justify-content: initial !important;
  }
}
