.quote-cards {
  display: flex;
  gap: 10px;
}

/* Style des cards */

.quote-card {
  min-width: 0px;
  max-width: 344px;
  margin: auto;
  flex: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 2px solid #999999;
}

.section {
  margin-top: 20px;
  max-height: 200px;
}
.expert-details {
  display: flex;
}

.expert-avatar {
  width: 55px;
  height: 55px;
  border-radius: 12px;
  opacity: 1;
}

.expert-name,
.card-titles {
  font-family: 'Poppins bold', sans-serif;
  font-size: 14px;
  color: #202b53;
}

.expert-location {
  font-size: 14px;
}
.expert-location,
.price {
  font-family: 'Poppins regular', sans-serif;
  color: #4188f2;
}

.price {
  font-size: 24px;
}

.card-details {
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  color: #aeb9d1;
  line-height: 21px;
  margin-top: 10px;
  max-height: 169px;
  overflow-y: auto;
}

.choose-card {
  border-radius: 8px;
  opacity: 1;
  border: 2px solid #4188f2;
  margin-top: 20px !important;
  font-family: 'Poppins bold', sans-serif;
  font-size: 12px;
  padding: 18px 20px;
  margin-bottom: 0;
  color: #ffffff;
  background: #4188f2 0% 0% no-repeat padding-box;
  cursor: pointer;
  text-align: center;
}

.selected-card {
  border: 2px solid #4188f2;
}

.selected-card .choose-card {
  color: #ffffff;
  background: #4188f2 0% 0% no-repeat padding-box;
  cursor: pointer;
}

/* .green-star, .grey-star {
    width: 23px;
    height: 23px;
    margin: 0 3px 0 3px;
    border-radius: 7px;
    opacity: 1;
} */

.expert-notation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #00000029;
  top: 0;
  left: 0;
}

.modal {
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px 36px 26px 46px;
  width: 672px;
  height: 364px;
  background-color: #ffffff;
  border-radius: 30px;
  position: relative;
}

.modal-expert-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 21px;
  color: #202b53;
}

.modal-expert-detail {
  font-family: 'Poppins regular', sans-serif;
  text-align: center;
  font-size: 14px;
  color: #aeb9d1;
  line-height: 21px;
}

.choose-expert {
  border-radius: 8px;
  opacity: 1;
  border: none;
  background: #4188f2 0% 0% no-repeat padding-box;
  margin: 0 auto;
  display: flex;
  color: #ffffff;
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  padding: 18px 40px;
  margin-bottom: 0;
  cursor: pointer;
}

.forward-modal {
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  color: #202b53;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
}

.quote-service-disclaimer {
  box-sizing: border-box;
  border: 1px solid #202b53;
  background-color: white;
  color: #202b53;
  font-family: 'Poppins regular';
  padding: 8px;
  font-size: 12px;
  margin-top: 30px;
}

.quote-service-disclaimer span {
  padding-left: 5px;
}

.green-star {
  width: 24px;
}

.grey-star {
  width: 24px;
}

@media (max-width: 992px) {
  .choose-card {
    padding: 18px 10px;
  }
}
