.toast-component-greetings {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 8px;
}

.style_toast-message__-UN8x {
  max-width: 100% !important;
}

.toast-header-greetings {
  font-family: 'Poppins bold', sans-serif;
  font-size: 14px;
  color: #202b53;
  margin-bottom: 8px;
  position: absolute;
  max-width: 500px;
  top: 90px;
  right: 150px;
  z-index: 1002;
}

.toast-background-greetings {
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-body-greetings {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 16px;
  max-width: 600px;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 80px;
  right: 120px;
  z-index: 1001;
  padding-top: 60px;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .toast-component-greetings {
    align-items: center;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 1001;
    padding: 16px;
  }

  .toast-header-greetings {
    background-color: white;
    border-radius: 8px;
    position: static; /* Réinitialise la position */
    text-align: center;
    max-width: 60%;
    top: auto;
    right: auto;
    margin-top: 25%;
    margin-bottom: -15px;
    padding: 16px;
  }

  .toast-background-greetings {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toast-body-greetings {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    position: static; /* Réinitialise la position */
    max-width: 60%;
    text-align: center;
    margin-top: 0;
    top: auto;
    right: auto;
    padding-top: 10px;
  }
}
