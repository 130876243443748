.article-template-columns {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5%;
  margin-bottom: 12px;
}

.template-columns-row {
  display: flex;
  gap: 50px;
}

.template-columns-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 32px;
  color: #202b53;
  line-height: 48px;
  margin-bottom: 24px;
}

.template-columns-content-paragraphs {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.template-columns-content-paragraph {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  color: #202b53;
  line-height: 32px;
  min-height: 16px;
  margin-bottom: 0px;
}

.paragraph-content-column {
  display: flex;
  flex-direction: column;
}

.paragraph-content-column span {
  display: block;
}

.template-columns-left {
  width: 50%;
}

.template-columns-right {
  width: 50%;
}

.template-columns-content-paragraphs
  .template-columns-content-paragraph:last-child {
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .template-columns-row {
    flex-direction: column;
    gap: 0;
  }

  .template-columns-left,
  .template-columns-right {
    width: 100%;
  }
}
