.article-template-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-bottom: 12px;
  max-width: 900px;
  box-sizing: border-box;
}

.template-center-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 32px;
  color: #202b53;
  line-height: 48px;
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
}

.template-center-content {
  display: flex;
  flex-direction: column;
}

.template-center-content-paragraph {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  color: #202b53;
  line-height: 32px;
  min-height: 16px;
  margin-bottom: 0;
  text-align: center;
}

.template-center-content-paragraph-bold {
  font-family: 'Poppins bold', sans-serif;
}

.template-center-content-paragraph-italic {
  font-family: 'Poppins italic', sans-serif;
}

.paragraph-content-column {
  display: flex;
  flex-direction: column;
}

.paragraph-content-column span {
  display: block;
}

.article-template-center h5 {
  font-family: 'Poppins bold', sans-serif;
  text-align: center;
}

.template-center-content-paragraphs
  .template-center-content-paragraph:last-child {
  margin-bottom: 24px;
}

.template-center-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 32px;
}

.template-center-btn-left {
  border: none;
  background-color: #4188f2;
  border-radius: 8px;
  display: flex;
  max-width: 400px;
  min-width: 200px;
}

.template-center-btn-left-link {
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  padding: 18px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.template-center-btn-right {
  border: none;
  background-color: #1c2947;
  border-radius: 8px;
  display: flex;
  max-width: 430px;
  min-width: 225px;
}

.template-center-btn-right-link {
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  padding: 18px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
