.article-template-simple {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5%;
  margin-bottom: 12px;
}

.template-simple-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 32px;
  color: #202b53;
  line-height: 48px;
  margin-bottom: 24px;
}

.template-simple-content-paragraphs {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.template-simple-content-paragraph {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  color: #202b53;
  line-height: 32px;
  min-height: 16px;
  margin-bottom: 0;
}

.template-simple-content-paragraph-bold {
  font-family: 'Poppins bold', sans-serif;
}

.template-simple-content-paragraph-italic {
  font-family: 'Poppins italic', sans-serif;
}

.paragraph-content-column {
  display: flex;
  flex-direction: column;
}

.paragraph-content-column span {
  display: block;
}

.template-simple-content-paragraphs
  .template-simple-content-paragraph:last-child {
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .template-simple-title {
    max-width: 500px;
  }
}
