.dropdown-wrapper {
  position: relative;
  margin-bottom: 20px;
}

/* Style de base pour tous les ComboBox */
.comboBox {
  border: 1px solid #d5d2d2;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

/* Lorsque le ComboBox est actif (c'est-à-dire pendant l'écriture) */
.comboBox:focus {
  border-color: #4188f2;
  outline: none;
}

/* Style pour l'erreur */
.comboBox.error {
  border-color: #ff3e3e;
}

.combobox__dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  margin-top: 5px;
  z-index: 1;
  max-height: 110px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-option {
  cursor: pointer;
  font-family: 'Poppins regular', sans-serif;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  font-size: 14px; /* Une taille de police confortable */
  color: #333; /* Couleur de texte sombre pour une bonne lisibilité sur fond blanc */
  transition: background-color 0.2s;
}

.dropdown-option:last-child {
  border-bottom: none;
}

.dropdown-option:hover {
  background-color: #007bff;
  color: #fff;
}

.dropdown-option.selected {
  background-color: #e6f7ff;
}

.hidden {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.autocomplete-list {
  animation: fadeIn 0.3s;
}

.auto-complete-red {
  font-family: 'Poppins regular';
  font-size: 14px;
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: #ff3e3e25;
  color: #ff3e3e;
  margin-top: 8px;
  display: block;
  border-radius: 8px;
}

.auto-complete-red > span {
  font-family: 'Poppins bold';
}
