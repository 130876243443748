.toast-component-no-expert {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 8px;
}

.style_toast-message__-UN8x {
  max-width: 100% !important;
}

.toast-background-no-expert {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-body-no-expert {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 16px;
  max-width: 450px;
  margin: auto;
  text-align: center;
}
