.doculegal {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-container {
  justify-content: center;
  align-items: center;
  background-color: #0d0e17;
  height: 70px;
  min-height: 70px;
  display: flex;
}

.footer-link-style {
  color: #41486e;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1024px) {
  .footer-container {
    background-color: #0d0e17;
  }
}

@media (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footerext {
    display: flex;
    background-color: #202b53;
    color: white;
    padding-top: 40px;
    padding-bottom: 5%;
    flex-direction: column;
    padding-left: 9%;
    padding-right: 9%;
    max-height: initial !important;
  }

  .footer-1.double-width {
    margin-left: 0 !important;
  }

  .footer-logos {
    flex-direction: column;
    margin: auto;
  }

  .doculegal {
    flex-direction: column;
    justify-content: center;
    margin: auto;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .footer-logos,
  .footer-container {
    flex-direction: column;
  }

  .footer-logos,
  .doculegal {
    width: 100%;
  }
}
