/* Style de la catégorie, avec le signe + ou - à droite */
.devis-form-category span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc; /* Couleur de bordure selon la charte graphique */
  position: relative; /* Pour la position absolue du signe */
}

.devis-form-category-button {
  background: none; /* Enlève le fond par défaut */
  border: none; /* Enlève la bordure par défaut */
  margin: 0; /* Réinitialise la marge par défaut */
  overflow: visible; /* Empêche les débordements */
  font: inherit; /* Utilise la police du parent */
  text-align: inherit; /* Utilise l'alignement de texte du parent */
  line-height: 24px; /* Réinitialise la hauteur de ligne par défaut */
  -webkit-appearance: none; /* Enlève l'apparence par défaut pour les navigateurs WebKit */
  -moz-appearance: none; /* Enlève l'apparence par défaut pour les navigateurs Mozilla */
  appearance: none; /* Enlève l'apparence par défaut pour tous les navigateurs */
  color: inherit; /* Utilise la couleur de texte du parent */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc; /* Couleur de bordure selon la charte graphique */
  position: relative; /* Pour la position absolue du signe */
  width: 100%;
}

/* Style pour le signe + ou - */
.devis-form-category-button::after {
  content: '+';
  color: #007bff; /* Couleur bleue selon la charte graphique */
  position: absolute;
  right: 10px;
}

/* Lorsque la catégorie est ouverte, changez le signe en - */
.devis-form-category-button.open::after {
  content: '-';
}

/* Style du conteneur des activités */
.devis-form-activities {
  background-color: #f8f8f8; /* Fond légèrement différent selon la charte graphique */
  padding-left: 10px;
  flex-wrap: wrap;
}

/* Style des lignes d'activités individuelles */
.devis-form-column-bi {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 5px 0; /* Espacement vertical des éléments */
}

.devis-form-column-tri {
  display: flex;
  align-items: flex-start;
  width: 33%;
  padding: 5px 0; /* Espacement vertical des éléments */
}

/* Style des cases à cocher */
.devis-checkbox {
  margin-right: 10px; /* Espace entre la case à cocher et le label */
}

/* Style des labels */
.devis-form-label-box {
  display: block; /* Permet au texte d'occuper toute la largeur disponible */
  cursor: pointer; /* Indique que le label est cliquable */
}

.category-counter {
  background-color: #e0e0e0; /* Default background color */
  color: black; /* Default text color */
  border-radius: 50%;
  padding: 0.45em !important;
  margin-left: 0.5em;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em; /* Align the text vertically */
  font-weight: bold;
  margin-right: 20px;
}

.counter-active {
  background-color: #4188f2; /* Background color when the counter is active */
  color: white; /* Text color when the counter is active */
}
