.progress-bar-steps {
  width: 75%;
  height: 8px;
  background-color: #eee;
  border-radius: 8px;
}

.progress-fill {
  width: 0;
  height: 100%;
  background: rgb(0, 212, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(65, 136, 242, 1) 100%
  );
  border-radius: 8px;
  transition: width 0.5s ease-in-out;
}

.devis-container-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 20px auto 40px auto;
}

.devis-progress-number {
  margin-left: 20px;
  position: relative;
}

.choose-expert {
  text-decoration: none;
}

.choose-expert:hover {
  text-decoration: underline;
}

#nbr {
  position: absolute;
  top: -17px;
  font-family: 'Poppins bold', sans-serif;
  font-size: 18px;
  line-height: 16px;
}

#experts {
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #202b53;
}

.devis-container {
  background-color: white;
  margin: 0 9%;
  padding: 30px 0;
  position: relative;
  margin-top: -80px;
  border-radius: 30px;
}

.grey-bg-v3 {
  position: absolute;
  top: -1863.014648px;
  left: 803px;
  width: 2944px;
  height: 3114px;
  z-index: -1;
}

.pale-pink-ring-devis {
  position: absolute;
  top: 140px;
  right: -55px;
  width: 110px;
  height: 110px;
}

.green-wave-devis {
  position: absolute;
  bottom: 143px;
  right: 72px;
  width: 190px;
}

.devis-left-column {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
}

.devis-title {
  font-size: 42px;
  font-family: 'Poppins bold', sans-serif;
  line-height: 60px;
  color: #0099df;
}

.background-reduce {
  height: 370px;
  overflow: hidden;
}

.devis-subtext {
  max-width: 621px;
  font-family: 'Poppins regular', sans-serif;
  color: #202b53;
  line-height: 38px;
  font-size: 16px;
  margin-top: 11px;
  margin: auto;
  margin-top: 2em;
}

.btn-center a {
  margin: auto;
}

.devis-success {
  font-family: 'Poppins regular', sans-serif;
  text-align: center;
  letter-spacing: 0px;
  color: #202b53;
  opacity: 1;
}

.devis-success-subtext {
  font-family: 'Poppins regular', sans-serif;
  text-align: center;
  letter-spacing: 0px;
  color: #202b53;
  opacity: 1;
}

.devis-btn-link {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.devis-btn-help {
  padding: 24px 24px;
  background-color: #202b53;
  font-size: 16px;
  color: white !important;
  font-family: 'Poppins regular', sans-serif;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #202b53;
  width: 215px;
  text-align: center !important;
  text-decoration: none !important;
}

.devis-btn-help:hover {
  text-decoration: underline !important;
  color: white !important;
}

.devis-btn-create-account {
  padding: 12px 24px;
  font-family: 'Poppins bold', sans-serif;
  background-color: #202b53;
  text-align: center !important;
  /*font: normal normal bold 16px/38px "Poppins";*/
  letter-spacing: 0px;
  border-radius: 8px;
  border-style: solid;
  border-color: #202b53;
  color: #ffffff;
  opacity: 1;
}

.devis-link {
  font-family: 'Poppins semibold', sans-serif;
  font-size: 16px;
  text-decoration: underline;
  padding: 12px 15px;
}

.devis-a-link {
  color: #4188f2 !important;
}

.enveloppe {
  width: 1074px;
  height: 836px;
  position: absolute;
  left: -305px;
  bottom: -239px;
  z-index: -1;
}

.devis-container-steps {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 5px 18px #00000026;
  padding: 30px;
  transition: max-height 0.5s linear, height 0.5s linear;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.devis-right-column {
  max-width: 80%;
  width: 100%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.devis-btn-steps {
  background-color: #4188f2;
  font-size: 16px;
  color: white;
  font-family: 'Poppins regular', sans-serif;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #4188f2;
  width: 120px;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'Poppins semibold', sans-serif;
  padding: 10px 32px;
}

.devis-backward-steps {
  border: none;
  background-color: white;
  font-family: 'Poppins semibold', sans-serif;
  line-height: 38px;
  font-size: 16px;
  text-decoration: underline;
}

.devis-checkbox {
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #d5d2d2;
  border-radius: 2px;
  appearance: none;
  width: 18px;
  height: 18px;
  transform: translateY(17%);
  min-width: 18px;
  min-height: 18px;
}

.devis-checkbox:checked {
  background-color: #4188f2;
  border-radius: 2px;
  background-image: url('../images/verifier_white.png');
  background-size: cover;
}

.devis-form-label {
  font-size: 16px;
  font-family: 'Poppins bold', sans-serif;
  line-height: 25px;
  color: #202b53;
  margin-bottom: 5px;
}

.devis-form-column-bi {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
}

.devis-form-column-tri {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  align-items: flex-start;
}

.devis-form-label-box {
  font-size: 16px;
  font-family: 'Poppins regular', sans-serif;
  line-height: 25px;
  color: #202b53;
  margin-left: 10px;
}

.devis-form-input {
  /* Reset. */
  border: unset;

  /* Style */
  border: 1px solid #d5d2d2;
  border-radius: 8px;
  padding: 8px;

  /* Arrow */
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  margin-bottom: 20px;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
}

.devis-form-input:focus-visible {
  outline: #4188f2 1px solid;
}

.devis-small-input {
  display: flex;
  align-items: center;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
}

.devis-small-input label {
  margin-right: 54px;
}

.devis-small-input input {
  border: 1px solid #d5d2d2;
  border-radius: 8px;
  padding: 8px;
  max-width: 50px;
}

.devis-medium-input {
  display: flex;
  align-items: center;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  margin-bottom: 23px;
}

.devis-medium-input label {
  max-width: 63%;
}

.devis-medium-input input {
  border: 1px solid #d5d2d2;
  border-radius: 8px;
  padding: 8px;
  max-width: 145px;
  margin-left: auto;
}

.devis-medium-input input:focus-visible {
  outline: #4188f2 1px solid;
}

.devis-small-input input:focus-visible {
  outline: #4188f2 1px solid;
}

/* Style radio button  */

.devis-radio-btn {
  display: flex;
  margin-bottom: 30px;
}

.devis-radio-btn div {
  margin-right: 30px;
  margin-top: 10px;
}

.devis-radio-btn div input {
  font-family: 'Poppins regular', sans-serif;
  margin-right: 10px;
  appearance: none;
  border: 1px solid #d5d2d2;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  transform: translateY(17%);
}

.devis-radio-btn div input:checked {
  background-color: #4188f2;
  border-radius: 100%;
  position: relative;
}

.devis-radio-btn div input:checked::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.devis-form-input-no-arrow {
  border: unset;
  border: 1px solid #d5d2d2;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 20px;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
}

.devis-form-input-no-arrow:focus-visible {
  outline: #4188f2 1px solid;
}

/* form step transition */
.form-step {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 1;
  max-height: 0;
  padding: 1%;
  transition: max-height 1s ease-out 1s, opacity 1s ease-out 1s,
    padding 1s ease-out 1s;
  overflow: hidden;
}

.form-step.hide {
  opacity: 0;
  max-height: 0;
  padding: 0;
  transition: all 1s ease-out;
}

/************************
***** FORM CHEKBOXES*****
************************/

.devis-form-prestations {
  display: flex;
  flex-direction: column;
  box-sizing: initial;
  padding-left: 32px;
  padding-right: 16px;
}

.devis-card-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 16px;
  border: 1px solid rgba(125, 125, 125, 0.5); /*#518ee9*/
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  min-height: 120px;
}

.devis-cc-icon {
  width: 38px;
  margin-right: 16px;
}

.devis-cc-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.devis-card-label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.devis-form-text {
  font-size: 14px;
  padding-right: 6em;
}

.devis-cc-input {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  appearance: none;
  padding: 0;
  margin: 0;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
}

.devis-cc-input::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 4px;
  right: 8px;
  top: 8px;
}

.devis-cc-input:checked {
  border-color: #518ee9;
}

.devis-cc-input:checked::after {
  border-color: #518ee9;
  background: url('../images/verifier_white.png');
  background-color: #518ee9;
  background-size: cover;
}

.devis-form-completion {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelOff label {
  color: rgb(189, 186, 186);
}

/* .devis-form-label-radio {

} */

.devis-wrap-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.devis-wrap-container .devis-width-50 {
  min-width: 230px;
  width: 50%;
  margin-right: 0;
}

.devis-wrap-container .devis-width-33 {
  min-width: 130px;
  width: 33%;
  margin-right: 0;
  display: flex;
}

.devis-name-surname-container {
  display: flex;
  justify-content: space-between;
}

.devis-name-surname-container > div {
  width: 48%;
}

.devis-form-span {
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #202b53;
  display: block;
  margin-bottom: 5px;
}

.devis-input-files {
  display: none;
}

.devis-consent-wrapper {
  margin-top: 20px;
}

.devis-consent-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.devis-input-file-label {
  cursor: pointer;
  position: relative;
  padding-left: 38px;
  color: #4188f2;
  text-decoration: underline;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  line-height: 21px;
}

.devis-input-file-label::before {
  content: '';
  position: absolute;
  left: 0px;
  top: -2px;
  width: 22px;
  height: 24px;
  background: url('../images/sending-file.svg');
  background-size: cover;
}

.devis-file-list {
  display: flex;
  flex-direction: column;
}

.devis-file-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.devis-item-name {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  line-height: 25px;
  max-width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.devis-item-icon {
  width: 10px;
  height: 10px;
  margin-left: 20px;
  cursor: pointer;
}

.devis-last-step-container {
  display: flex;
  flex-direction: column;
}

.devis-last-step-container img {
  width: 68px;
  margin: auto;
  margin-bottom: 40px;
}

.h5-style {
  font-size: 16px;
  font-family: 'Poppins bold', sans-serif;
  line-height: 25px;
  color: #202b53;
  margin: auto;
}

.devis-last-step-container p {
  font-family: 'Poppins regular', sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #202b53;
  text-align: center;
  margin-bottom: 50px;
}

.grey-p-regular {
  text-align: center;
  color: #202b53;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 30px;
}

.devis-link-account {
  font-family: 'Poppins bold', sans-serif;
  text-decoration: underline;
  color: #202b53;
  margin: 0;
  padding: 0;
}

.devis-link-account:hover {
  color: #9ea8be;
}

.grey-p-semibold {
  font-family: 'Poppins semibold', sans-serif;
  text-align: center;
  color: #202b53;
  font-size: 14px;
  line-height: 21px;
}

.devis-creer-compte {
  padding: 25px 34px;
  background-color: #202b53;
  font-size: 16px;
  color: white;
  font-family: 'Poppins semibold', sans-serif;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #202b53;
  margin: 20px auto 0 auto;
}

.devis-retour-accueil {
  padding: 12px 32px;
  background-color: #4188f2;
  font-size: 16px;
  color: white;
  font-family: 'Poppins semibold', sans-serif;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #4188f2;
  width: 240px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
}

/* Erros */

.devis-error {
  outline: #ff3e3e 1px solid;
}

.devis-cc-input-error {
  border-color: #ff3e3e;
}

.devis-checkbox-error {
  border: 1px solid #ff3e3e;
}

/* Remove btn last step */

.remove-btn {
  display: none;
}

.devis-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.devis-btn-steps-all {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1500px) {
  .background-reduce {
    display: none;
  }

  .devis-container {
    margin-top: 100px;
  }
}

@media (max-width: 1440px) {
  .devis-container {
    justify-content: space-between;
  }

  .devis-right-column {
    max-width: 80%;
    margin: auto;
  }

  .devis-btn-link {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .devis-container {
    flex-direction: column;
  }

  .background-reduce {
    min-height: 200px;
    height: initial;
    margin-top: 70px;
  }

  .devis-left-column {
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
  }

  .devis-title {
    line-height: 30px;
  }

  .enveloppe {
    display: none;
  }

  .devis-right-column {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    padding: 0 30px;
  }

  .devis-btn-link {
    align-items: center;
    flex-direction: column;
  }

  .devis-radio-btn div {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
  }

  .devis-radio-btn div input {
    flex-shrink: 0;
  }
}

@media (min-width: 320px) and (max-width: 571px) {
  .devis-container-steps {
    max-width: 90%;
  }

  .progress-bar-steps {
    margin-left: 20px;
  }

  .devis-left-column {
    margin-top: 0px;
  }

  .devis-container {
    min-height: initial;
    padding: 0;
    margin: -80px 0 20px 0;
  }

  .devis-title {
    line-height: 30px;
    margin: 30px 0;
  }

  .devis-radio-btn div {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
  }

  .devis-radio-btn div input {
    flex-shrink: 0;
  }
}
