.article-page {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1440px;
  margin: auto;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 170px;
}

.link-article {
  color: #4188f2 !important;
  text-decoration: none;
}

.link-article:hover {
  text-decoration: underline;
}

.article-page-red-link {
  margin-bottom: 18px;
  font-family: 'Poppins regular', sans-serif;
  color: #202b53;
  font-size: 16px;
}

.article-separation {
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.article-separation::after {
  position: absolute;
  content: '>';
  font-family: 'Poppins regular', sans-serif;
  color: #202b53;
  font-size: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -32%);
}

.article-red-link-item a {
  font-family: 'Poppins regular', sans-serif;
  color: #202b53;
  font-size: 16px;
  text-decoration: none;
}

.article-item-underline {
  text-decoration: underline;
}

.article-page-title {
  font-family: 'Poppins bold', sans-serif;
  font-size: 59px !important;
  color: #202b53;
  line-height: 78px;
  margin-bottom: 36px;
  word-wrap: break-word;
}

.article-page-footer {
  width: 100%;
  height: 125px;
  background-color: #4188f2;
  margin-top: auto;
}

@media (min-width: 992px) and (max-width: 1536px) {
  .article-page {
    margin-top: 110px;
  }
}
@media (max-width: 992px) {
  .article-page {
    margin-top: 90px;
  }
}

@media (max-width: 425px) {
  .article-page-title {
    font-size: 48px !important;
    line-height: 68px;
  }
}
