.partners-container {
  display: flex;
  flex-direction: column;
  padding-right: 9%;
  padding-left: 9%;
  margin-top: 150px;
  position: relative;
  align-items: center;
}

.partners-band {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  border-radius: 20px;
  margin: 0px 10px 0;
  background-color: white;
}

.partners-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 9% 25px 9%;
  width: 100%;
}

.partners-span {
  color: #202b53;
  font-family: 'Poppins semibold', sans-serif;
  font-size: 25px;
  padding-top: 15px;
  text-align: left;
  padding-left: 9%;
}

.partners-band > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logo-pool {
  max-width: 120px;
  margin-top: 20px;
}

.logo-agence {
  max-width: 130px;
  margin-top: 20px;
}

.logo-quest {
  max-width: 225px;
  margin-left: -15px;
}

.logo-est {
  max-width: 125px;
  min-width: 100px;
  width: 100%;
  margin-top: 20px;
}

.whycec {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.whycec-container {
  display: flex;
  flex-direction: row;
}

.equa {
  flex: 1;
}

.two {
  padding-top: 214px;
  text-align: left;
  padding-right: 9%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.two h2 {
  font-size: 50px;
  padding-left: 15%;
  text-align: left;
}

.logo-pad img {
  margin-right: 1em;
}

.img-men {
  width: auto;
  height: 716px;
  margin-top: 70px;
}

.titleWhy {
  width: 100%;
}

.titleWhy span {
  font-size: 50px;
}

.textwhy p {
  text-align: left;
  font-size: 25px;
  padding-left: 15%;
}

.textwhy span {
  font-size: 25px;
}

.text-blue {
  color: #0099df;
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .partners-container {
    margin-top: 300px;
  }

  .img-men {
    width: auto;
    height: 450px;
    margin-top: 70px;
  }

  .two {
    padding-top: 10%;
    text-align: left;
  }

  .two h2 {
    text-align: left;
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .partners-container {
    margin-top: 80px;
  }

  .img-men {
    width: auto;
    height: 340px !important;
    margin-top: 40px;
  }

  .two {
    padding: 3% 9%;
    text-align: left;
  }

  .two h2 {
    padding-left: 0;
    text-align: left;
    width: 100%;
  }

  .textwhy {
    padding-left: 0;
  }

  .logo-pad {
    padding-left: 9%;
  }

  .partners-span {
    padding-left: 9%;
  }
}

@media (max-width: 992px) {
  .partners-band,
  .partners-logos {
    display: flex;
    flex-wrap: wrap;
  }

  .two {
    padding: 40px 16px;
    text-align: left;
  }

  .img-men {
    width: auto;
    height: 340px !important;
    margin-top: 40px;
  }

  .two h2 {
    padding-left: 0%;
    font-size: 23px !important;
    line-height: 23px;
    text-align: -webkit-center;
  }

  .textwhy p,
  .textwhy span {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    padding-left: 0%;
  }
}

@media (max-width: 425px) {
  .partners-band,
  .partners-logos {
    flex-direction: column;
  }

  .partners-logos > div {
    margin: auto;
    padding: 5px;
  }
}

@media (max-width: 768px) {
  .whycec-container {
    display: flex;
    flex-direction: column;
  }
}
