.contact-container {
  background-color: white;
  position: relative;
}

.contact-green-wave {
  position: absolute;
  top: 390px;
  right: 247px;
}

.point {
  margin-left: 1em;
}

.pale-pink-ring {
  position: absolute;
  width: 110px;
  height: 110px;
  top: 800px;
  left: 115px;
}

.contact-reviews-and-form {
  display: flex;
  flex-direction: column;
}

.contact-reviews {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.review-card {
  display: flex;
  background-color: white;
  max-width: 376px;
  border-radius: 20px;
  margin: 10px 10px 0;
  z-index: 1;
  box-shadow: 0px 5px 18px #00000026;
}

.star {
  margin: 0 3px;
}

.review-card > div {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

.review-card > div p {
  font-family: 'Poppins semibold', sans-serif;
  padding-top: 30px;
}

.review-name {
  margin-top: auto;
}

.review-card h3 {
  font-size: 18px;
}

.contact-form h2 {
  color: black;
  line-height: 78px;
  font-size: 42px;
  max-width: 762px;
  margin: auto;
  text-align: center;
  margin-top: 0;
}

.contact-icons {
  width: 24px;
  height: 24px;
  margin-top: 2px;
}

.contact-infos {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.contact-link {
  font-size: 18px;
  font-family: 'Poppins bold', sans-serif;
  text-decoration: none;
  margin-bottom: 5px;
  color: blue !important;
}

.contact-infos h3 {
  font-size: 18px;
  color: black;
  margin-bottom: 30px;
}

.contact-infos-row {
  display: flex;
}
.contact-infos-row p {
  color: black;
  font-size: 18px;
}

.contact-contact-form {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 2em;
  border-radius: 30px;
}

.background-img {
  height: 550px;
  overflow: hidden;
}

.background-img-3 {
  height: 500px;
  overflow: hidden;
}

img.banner {
  width: 100%;
}

.contact-container-form {
  display: flex;
  flex-direction: column;
  max-width: 825px;
  width: 100%;
  margin-bottom: 15px;
}

.contact-container-infos {
  max-width: 1600px;
  margin: -12% 8% 0 8%;
  background: white;
  padding: 2% 4% 4% 4%;
  border-radius: 30px;
}

.contact-container-infos p {
  font-size: 16px;
}

.text-contact span {
  font-size: 16px;
}

.title-bluesoft {
  font-size: 42px;
  font-family: 'Poppins Bold', sans-serif;
}

.contact-container h2 {
  text-align: center;
  margin-bottom: 30px;
}

.contact-form-row {
  display: flex;
  justify-content: space-between;
}

.contact-form-item {
  width: 49%;
  position: relative;
  margin-bottom: 20px;
}

.contact-form-item input {
  background-color: #f5f5f5;
  color: black;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  padding: 0 8px;
  border: 1px solid black;
  border-radius: 8px;
  width: 100%;
  height: 40px;
}

.contact-form-item label,
.contact-form-textarea label {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 20px;
  padding: 0 30px 0 10px;
  color: black;
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: 'Poppins regular', sans-serif;
}

.contact-form-textarea {
  position: relative;
}

.contact-form-textarea textarea {
  background-color: #f5f5f5;
  color: black;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  padding: 8px 8px;
  margin-bottom: 20px;
  width: 100%;
  height: 240px;
  resize: none;
  border: 1px solid black;
  border-radius: 8px;
}

.contact-btn {
  margin-left: auto;
  background-color: #4188f2;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #4188f2;
  font-size: 14px;
  font-family: 'Poppins semibold', sans-serif;
  padding-left: 1em;
  padding-right: 1em;
  color: white;
}

.contact-container-global {
  display: flex;
  flex-direction: column;
  max-width: 1305px;
  width: 100%;
  margin: auto;
}

.text-contact {
  margin-bottom: 30px;
  text-align: center;
}

/* Modal Validation */

.contact-modal-validation {
  background-color: #d9fdf9;
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
}

.contact-modal-validation.show {
  border: 2px solid #3ac1ae;
  padding: 24px 24px 12px 24px;
  max-height: 200px;
}

.contact-modal-validation h4 {
  color: #202b53;
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  text-align: center;
}

.contact-modal-validation p {
  color: #202b53;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  text-align: center;
}

/* Modal Erreur */

.contact-modal-erreur {
  background-color: #ffdfdf;
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
}

.contact-modal-erreur.show {
  border: 2px solid #ff3e3e;
  padding: 24px 24px 12px 24px;
  max-height: 200px;
}

.contact-modal-erreur h4 {
  color: #202b53;
  font-family: 'Poppins bold', sans-serif;
  font-size: 16px;
  text-align: center;
}

.contact-modal-erreur p {
  color: #202b53;
  font-family: 'Poppins regular', sans-serif;
  font-size: 14px;
  text-align: center;
}

/* Erreur message */

.contact-error {
  border-color: #ff3e3e !important;
}

/* Fioritures */

.wave {
  position: absolute;
  right: 50%;
  top: 730px;
}

#contactAn {
  position: absolute;
  top: 700px;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .contact-contact-form {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 200px) and (max-width: 1024px) {
  .contact-reviews {
    align-items: center;
    margin-top: 0;
  }

  img.star {
    width: 25px;
  }

  .contact-container-infos p {
    line-height: 20px;
    font-size: 16px;
  }

  .contact-container-form {
    align-items: center;
    margin-bottom: 50px;
  }
  .contact-contact-form {
    display: flex;
    align-items: center;
  }

  .contact-form-item {
    width: 90%;
  }

  .contact-link {
    margin-left: auto;
    margin-right: auto;
  }

  .contact-infos h3 {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-form-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .contact-form-textarea {
    width: 90%;
  }

  .contact-btn {
    margin-left: 0;
  }

  .contact-icons {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .contact-contact-form {
    flex-direction: column-reverse;
  }
}

@media (min-width: 320px) and (max-width: 677px) {
  .contact-reviews {
    flex-direction: column;
    align-items: center;
  }

  .contact-container-infos {
    margin-top: 79px;
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-left: 0;
    padding-right: 0;
  }

  .contact-contact-form {
    padding: 0.5em;
  }

  .text-contact {
    text-align: center;
  }

  .background-img {
    display: none;
  }
}
