.solution-container {
  display: flex;
  flex-direction: column;
  padding-right: 9%;
  padding-left: 9%;
  margin-top: 25px;
  position: relative;
  background-color: #f5f5f5;
  padding-bottom: 85px;
}

.solution-container-w {
  display: flex;
  flex-direction: column;
  padding-right: 9%;
  padding-left: 9%;
  margin-top: 25px;
  position: relative;
  padding-bottom: 85px;
}

.purple-ring {
  position: absolute;
  right: 443px;
  top: -86px;
}

.solution-title {
  text-align: center;
}

.solution-title h2 {
  font-size: 50px;
  margin-top: 1em;
}

.solution-title p {
  color: #202b53;
  margin-bottom: 0;
  line-height: 30px;
  font-size: 25px;
}

.solution-grid {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.solution-card {
  border: none;
  border-radius: 20px;
  margin-top: 1em;
  width: 350px;
  height: 460px;
  padding: 26px;
  padding-top: 0;
}

.solution-card-w {
  box-shadow: 0px 5px 18px #00000026;
  border: none;
  border-radius: 20px;
  margin-top: 1em;
  width: 22%;
  background-color: white;
  padding: 35px;
  padding-top: 0;
}

.solution-icons {
  width: 130px;
  height: 130px;
  padding-top: 32px;
}

.solution-icons-small {
  width: 60px;
  height: 60px;
}

.solution-card h3 {
  padding-top: 0;
  text-align: center;
  font-size: 29px;
}

.solution-card-w h3 {
  padding-top: 0;
  text-align: center !important;
  font-size: 29px;
}

.solution-card p {
  color: #202b53;
  line-height: 27px;
  text-align: center;
  margin-top: 0px;
  font-size: 20px;
}

.solution-card-w p {
  color: #202b53;
  line-height: 27px;
  text-align: center !important;
  margin-top: 0px;
  font-size: 20px;
}

.icons {
  text-align: center;
  margin-top: 20px !important;
}

.solution-btn {
  padding: 24px 32px;
  background-color: #4188f2;
  font-size: 16px;
  color: white !important;
  font-family: 'Poppins regular', sans-serif;
  box-sizing: initial;
  border-radius: 8px;
  border-style: solid;
  border-color: #4188f2;
  width: 240px;
  margin: auto;
  margin-top: 50px;
  font-size: 14px;
  font-family: 'Poppins semibold', sans-serif;
  padding: 25px 34px;
  text-align: center !important;
  text-decoration: none !important;
}

#nos-solutionsAn {
  position: absolute;
  top: -70px;
}

.solution-subtitle-b h3 {
  color: #307df1;
  min-height: 90px;
  margin-top: 10px;
}

.solution-subtitle-y h3 {
  color: #f3be45;
  min-height: 90px;
  margin-top: 10px;
}

.solution-subtitle-g h3 {
  color: #30bda8;
  min-height: 90px;
  margin-top: 10px;
}

.solution-subtitle-r h3 {
  color: #f34a4a;
  min-height: 90px;
  margin-top: 10px;
}

@media (min-width: 1440px) and (max-width: 1536px) {
  .solution-card {
    margin: 40px 10px 0;
  }

  .solution-card-w {
    margin: 40px 10px 0;
  }

  .solution-btn {
    margin-top: 40px;
  }

  .solution-card h3 {
    padding-top: 40px;
  }

  .solution-card-w h3 {
    padding-top: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .solution-card {
    margin: 20px 10px 0;
  }

  .reswrap {
    flex-wrap: initial;
  }

  .solution-card-w {
    margin: 20px 10px 0;
  }

  .solution-btn {
    margin-top: 20px;
  }

  .solution-title h2 {
    max-width: none;
  }

  .solution-card h3 {
    padding-top: 20px;
  }

  .solution-card-w h3 {
    padding-top: 20px;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .solution-icons-small {
    height: 50px;
    width: 50px;
  }

  .solution-title p {
    font-size: 17px;
}

  .solution-container {
    padding-right: 5%;
    padding-left: 5%;
    margin: 25px 40px;
    border-radius: 20px;
    padding-bottom: 36px;
  }

  .solution-icons {
    width: 47px;
    height: 47px;
    padding-top: 0;
    margin-right: 18px;
  }

  .solution-container-w {
    padding-bottom: 0px;
  }

  .solution-card-w .solution-subtitle-r {
    margin: auto;
  }

  .solution-card-w .solution-subtitle-g {
    margin: auto;
  }

  .solution-card-w .solution-subtitle-b {
    margin: auto;
  }

  .solution-card-w .solution-subtitle-y {
    margin: auto;
  }

  .solution-title {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }

  .solution-title h2 {
    max-width: 100%;
    text-align: center;
    margin-bottom: 35px;
  }

  .solution-title p {
    min-width: auto;
    margin-left: 0;
    margin-bottom: 40px;
  }

  .solution-grid {
    display: flex;
    flex-direction: column;
  }

  .solution-card {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    height: auto;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
  }

  .solution-card p {
    line-height: 20px;
    font-size: 14px;
  }

  .solution-card-w {
    display: flex;
    flex-direction: column;
    margin: 20px 0 5px 0;
    width: 100%;
    align-items: flex-start;
    min-height: 184px;
    height: auto;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 65px;
    padding: 0px 20px 20px 20px;
  }

  .solution-card-w p {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .solution-card-w .icons {
    margin-top: -25px !important;
    margin-bottom: 0px !important;
    margin: auto;
  }

  .solution-card h3 {
    padding-top: 5px;
  }

  .solution-card-w h3 {
    margin-top: 20px;
  }

  .solution-subtitle-g h3 {
    min-height: 9px;
    margin-top: 10px;
    line-height: 20px;
    font-size: 16px;
    text-align: left;
  }

  .solution-subtitle-r h3 {
    min-height: 9px;
    margin-top: 10px;
    line-height: 20px;
    font-size: 16px;
    text-align: left;
  }

  .solution-subtitle-y h3 {
    min-height: 9px;
    margin-top: 10px;
    line-height: 20px;
    font-size: 16px;
    text-align: left;
  }

  .solution-subtitle-b h3 {
    min-height: 9px;
    margin-top: 10px;
    line-height: 20px;
    font-size: 16px;
  }

  .solution-subtitle-g p {
    text-align: left;
  }

  .solution-subtitle-r p {
    text-align: left;
  }

  .solution-subtitle-y p {
    text-align: left;
  }
}

@media (max-width: 425px) {
  .solution-btn {
    width: auto;
  }
}
